import React from "react";
import ThankYou from "../components/ThankYou/ThankYou";
import styled from "styled-components";

const ThankYouPage = () => {
    return (
        <Wrapper>
            <ThankYou />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default ThankYouPage;
