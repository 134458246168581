import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 1rem 5rem;
        width: 95%;
        margin: auto;
        max-width: 1200px;

        svg {
            color: var(--clr-green-dark);
            font-size: 7.5rem;
        }
    }
`;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--clr-green-dark);
    border-radius: var(--radius);
    padding: 1rem;
    outline: none;
    border: none;
    color: var(--clr-grey-10);
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.1rem;
    flex: 1;

    &:disabled {
        opacity: 0.6;
        background-color: var(--clr-grey-5);
    }
    svg {
        height: 1.1rem;
    }
    &:hover {
        opacity: 0.8;
    }
`;

const StyledP = styled.p`
    font-size: 1rem;
    color: var(--clr-grey-5);
    text-align: center;
`;

export { StyledDiv, StyledButton, StyledP };
