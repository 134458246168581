import React from "react";
import { TiTick } from "react-icons/ti";
import Title from "../UI/Title/Title";
import { StyledDiv, StyledButton, StyledP } from "./ThankYouStyled";
import { Link } from "gatsby";

const ThankYou = () => {
    return (
        <StyledDiv className="Wrapper">
            <TiTick />
            <Title style={{ fontSize: "3rem" }}>Thank you</Title>
            <StyledP style={{ margin: "1rem 0" }}>Some paragraph, please fill it with some text or remove it from ThankYou.js file</StyledP>
            <Link to="/">
                <StyledButton>Return to main page</StyledButton>
            </Link>
        </StyledDiv>
    );
};

export default ThankYou;
